
import { Component, Vue, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'

@Component({
  components: {},
  computed: {
    ...mapGetters('GapModule', ['getFrameworkSelectedHistory',]),
    ...mapGetters('PolimorficModule', ['getFrameworkSelected',]),
  },
})
export default class ComplianceSumaryComponent extends Vue {
  private contentCompliance = ''
  private contentPending = ''
  private versionApp = this.$store.state.GapModule.GapState.version_categories

  mounted () {
    this.contentCompliance = this.$store.state.GapModule.GapState.completedInformation
    this.contentPending = this.$store.state.GapModule.GapState.pendingInformation
  }

  private routerBack () {
    this.$router.go(-1)
  }
}
